<template>
  <div>
    <form role="form" class="needs-validation" @submit.prevent="processGet">
      <div class="form-group row align-items-center">
        <div class="col-md-12">
          <base-input placeholder="아이디"
                      name="userId"
                      :error="_getError('userId')"
                      :maxlength="50"
                      v-validate="'required|email'"
                      v-model="model.userId">
          </base-input>
        </div>
        <div class="col-md-12">
          <base-input placeholder="이름"
                      name="name"
                      :error="_getError('name')"
                      :maxlength="20"
                      v-validate="'required'"
                      v-model="model.name">
          </base-input>
        </div>
        <div class="col-md-12">
          <base-input group>
            <input type="text"
                   name="mobileNo"
                   v-validate="'required'"
                   v-model="model.mobileNo"
                   :error="_getError('mobileNo')"
                   :maxlength="13"
                   class="form-control"
                   placeholder="휴대폰번호"
                   aria-describedby="button-addon2"
                   @keyup="phoneMask"
                   :readonly="otpStatus == 'Y'">
<!--            todo-->
            <div class="input-group-append" v-if="otpStatus == 'N'">
              <button class="btn btn-outline-primary" type="button" @click="requestOtp">OTP 발송</button>
            </div>
          </base-input>
        </div>
        <div class="col-md-12 row" v-if="otpStatus == 'R'">
          <div class="col-md-10">
            <base-input group>
                <input type="text"
                       name="otp"
                       v-validate="'required'"
                       v-model="model.otp"
                       :maxlength="6"
                       class="form-control"
                       placeholder="인증번호 입력"
                       aria-describedby="button-addon2">
                <div class="input-group-append">
                  <button class="btn btn-outline-primary" v-if="this.otpTime.otpProgressTime !== 0" type="button" @click="confirmOtp">인증</button>
                  <button class="btn btn-outline-primary" v-else type="button" @click="requestOtp">재발송</button>
                </div>
              </base-input>
          </div>
          <div class="col-2 text-center" style="margin-top: 0.7rem;">
            <span class="text-danger">{{ this.otpTime.convertTime }}</span>
          </div>
        </div>
      </div>
      <div class="text-center">
        <base-button type="kakao" wide native-type="submit">비밀번호 찾기</base-button>
      </div>
    </form>
  </div>
</template>

<script>
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { USER_API_PREFIX, RESULT_CODE, SITE_CODE } from '@/shared/util/const';
  import swal from "sweetalert2";

  export default {
    inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
    name: 'FindPasswordForm',
    mixins: [ commJsUtilMixin, commValidatorMixin ],
    data() {
      return {
        otpStatus : 'N',       //발송전 : N , 발송완료 확인전 : R , 확인완료 : Y
        model: {
          userId: '',
          name: '',
          mobileNo: '',
          otp : ''
        },
        otpTimer : null,
        otpTime : {
          defaultTime: 180,
          otpProgressTime: 0,
          convertTime : '',
        }
      }
    },
    methods: {
      get() {
        let chk = true;
        if('Y' != this.otpStatus) {
          this.alertMsg('OTP인증을 진행해 주세요.')
          chk = false;
        }

        if(chk){
          this.$axios.get(`${USER_API_PREFIX}/find-password`, {
            params: {
              userId: this.model.userId,
              name: this.model.name,
              mobileNo: this.model.mobileNo,
              domain: SITE_CODE.USER
            }
          }).then(res => {
            if (res.data.result.code === RESULT_CODE.SUCCESS) {
              let seqno = res.data.result.seqno;
              if(seqno) {
                this.$emit('input', {
                  isOk: true,
                  seqno: seqno,
                  otp: this.model.otp
                });
              } else {
                this.notify('warning');
              }
            } else {
              this.notify('warning', res.data.result.message);
            }
          });
        }
      },
      processGet() {
        this._validateBeforeSubmit(this.get);
      },
      /**
       * 키 이벤트로 핸드폰 번호 형식으로 문자열 변경
       */
      phoneMask() {
        this.model.mobileNo = this._autoHyphenPhoneNumber(this.model.mobileNo);
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      /**
       * OTP 인증 요청
       */
      requestOtp() {
        let chk = true;
        if(this.checkNull(this.model.userId)) {
          this.alertMsg('아이디를 입력해 주세요.')
          chk = false;
        }
        if(this.checkNull(this.model.mobileNo)) {
          this.alertMsg('전화번호를 입력해 주세요.')
          chk = false;
        }
        this.model.otp = '';
        if(chk){
          this.$axios.post(`${USER_API_PREFIX}/find-password/request-otp`, {
            userId    : this.model.userId ,
            mobileNo  : this.model.mobileNo,
          }).then(res => {
            if(res.data.result.code === RESULT_CODE.FAIL) {
              this.alertMsg(res.data.result.message);
            } else {
              this.otpStatus = 'R';
              // OTP 타이머 시작
              this.startOtpTimer();
            }
          }).catch(error => {
            console.log(error.response)
          })
        }
      },
      /**
       * OTP 인증 처리
       */
      confirmOtp() {
        let chk = true;
        if(this.checkNull(this.model.otp)) {
          this.alertMsg('OTP인증번호를 입력해 주세요.')
          chk = false;
        }
        if(this.otpTime.otpProgressTime == 0){
          this.alertMsg('인증시간이 초과하였습니다. 재인증을 진행해주세요.')
          chk = false;
        }

        if(chk){
          this.$axios.post(`${USER_API_PREFIX}/find-password/confirm-otp`, {
            userId: this.model.userId,
            otp: this.model.otp
          }).then(res => {
            if(res.data.result.code === RESULT_CODE.FAIL) {
              this.alertMsg(res.data.result.message);
              this.model.otp = ''
            }else{
              this.otpStatus = 'Y';
              clearInterval(this.otpTimer);
            }
          }).catch(error => {
            console.log(error.response)
          })
        }
      },

      /**
       * OTP 타이머 시작
       */
      startOtpTimer() {
        this.otpTime.otpProgressTime = this.otpTime.defaultTime;
        this.convertSecondsToTimeFormat(this.otpTime.otpProgressTime)

        if(this.otpTimer != null){
          clearInterval(this.otpTimer);
        }
        this.otpTimer = setInterval(this.convertProgressTime, 1000)
      },
      /**
       * OTP 인증 남은시간 처리
       */
      convertProgressTime() {
        let timer = this.otpTime.otpProgressTime
        this.otpTime.otpProgressTime = timer - 1

        // 타이머 초를 분 단위로 변환
        this.convertSecondsToTimeFormat(this.otpTime.otpProgressTime)

        // 타이머 종료시 처리
        if(this.otpTime.otpProgressTime === 0) {
          clearInterval(this.otpTimer)
        }
      },
      /**
       * 초를 분 단위로 변환
       * @param convertSeconds
       */
      convertSecondsToTimeFormat(convertSeconds) {
        let minutes = Math.floor(convertSeconds / 60)
        let seconds = (convertSeconds % 60)
        this.otpTime.convertTime = minutes + ':' + this.zeroLeftleftPadding(seconds)
      },
      /**
       * 숫자가 1자리수일 때 2자리수로 변환
       * @param target
       * @returns {*}
       */
      zeroLeftleftPadding(target) {
        return target.toString().length === 1 ? ('0' + target) : target.toString()
      },
      /**
       * null check
       */
      checkNull(param){
        if(param === undefined || param === null || '' == param){
          return true;
        }else{
          return false;
        }
      },
      /**
       * 알럿메시지 출력
       */
      alertMsg(msg , title){
        let titleMsg = title ? title : '경고';
        let type = title ? 'info' : 'warning';
        swal({
          title: titleMsg,
          text: msg,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-'+type,
          type: type,
          width: 400
        });
      },
    }
  };
</script>
<style></style>
