<template>
  <div></div>
</template>
<script>
export default {
  name: 'LoginSuccess',
  created () {
    //로그인 성공후 세션정보 저장
    this.$store.dispatch('SessionStore/callSession', this.$route)
      .then(() => {
        // do nothing
    });
  }
}
</script>
