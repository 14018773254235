<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-kakao py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h2 class="text-kakao">Kakao Biz Message</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <!-- Table -->
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8">
          <div class="card border-0">
            <div class="card-header bg-transparent">
              <div class="font-weight-bold text-center mt-2 mb-4">아이디 찾기</div>
              <div class="text-center">
                <router-link to="/user/login" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--text">로그인 하러가기</span>
                </router-link>
                <router-link to="/user/find-password" class="btn btn-neutral btn-icon">
                  <span class="btn-inner--text">비밀번호 찾기</span>
                </router-link>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-5">
              <!-- 아이디 찾기 폼 -->
              <find-id-form v-if="!isOk"
                @input="findSuccess">
              </find-id-form>

              <!-- 아이디 찾기 결과 -->
              <find-id-result v-else
                :user-id="userId">
              </find-id-result>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FindIdForm from './FindIdForm';
import FindIdResult from './FindIdResult';

export default {
  name: 'FindId',
  components: {
    FindIdForm,
    FindIdResult
  },
  data() {
    return {
      isOk: false,
      userId: ''
    }
  },
  methods: {
    findSuccess(payload) {
      this.isOk = payload.isOk;
      this.userId = payload.userId;
    }
  }
};
</script>
<style>
  .bg-gradient-kakao{
    background : linear-gradient(87deg, #FFCD00 0, #F7F008 100%) !important;
  }
  .bg-kakao{
    background : #FFCD00 !important;
  }
  .text-kakao{
    color : #433530 !important;
  }
  .bg-kakao-dark{
    background : #433530 !important;
  }
  .fill-kakao{
    background : #433530 !important;
  }
  .btn-kakao{
    background : #FFCD00 !important;
    border-color : #FFCD00 !important;
  }
</style>
