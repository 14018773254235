<template>
  <div></div>
</template>

<script>
  import { USER_API_PREFIX } from '@/shared/util/const';

  export default {
    name: 'Logout',
    created() {
      sessionStorage.clear();
      localStorage.clear();
      location.href = `${USER_API_PREFIX}/logout-process`;
    }
  }
</script>
