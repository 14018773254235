<template>
  <div>
    <form role="form" class="needs-validation" @submit.prevent="processModify">
      <base-input placeholder="새 비밀번호"
                  type="password"
                  ref="password"
                  name="password"
                  :error="_getError('password')"
                  :maxlength="20"
                  v-validate="'required|password'"
                  v-model="model.password">
      </base-input>

      <base-input placeholder="새 비밀번호 확인"
                  type="password"
                  name="confirmedPassword"
                  :error="_getError('confirmedPassword')"
                  :maxlength="20"
                  v-validate="'required|confirmed:password'"
                  v-model="model.confirmPassword">
      </base-input>

      <div class="text-center">
        <base-button type="primary" wide native-type="submit">비밀번호 변경</base-button>
      </div>
    </form>

    <p class="mt-4">
      <small>비밀번호는 영문 대문자, 영문 소문자, 숫자, 특수문자 중 <span class="text-danger font-weight-bold">2종류 이상을 조합하여 최소 10자리 이상, 또는 3종류 이상을 조합하여 최소 8자리 이상, 최대 20자 길이</span>로 구성 해야 합니다.</small>
    </p>
    <p class="mb-0">
      <small>특수문자 중 <span class="text-danger font-weight-bold">" ' \ [ ] & Space(공백문자) ? % 사용하실 수 없습니다.</span></small>
    </p>
  </div>
</template>

<script>
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { USER_API_PREFIX, RESULT_CODE } from '@/shared/util/const';

  export default {
    inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
    name: 'FindPasswordChangeForm',
    mixins: [ commJsUtilMixin, commValidatorMixin ],
    props: {
      seqno: {
        type: Number,
        default: undefined,
        description: '고유번호'
      },
      otp: {
        type: String,
        default: undefined,
        description: 'OTP 인증번호'
      }
    },
    data() {
      return {
        model: {
          password: '',
          confirmPassword: ''
        }
      }
    },
    methods: {
      modifyPassword() {
        this.$axios.put(`${USER_API_PREFIX}/change-password`, {
          seqno: this.seqno,
          newPassword: this.model.password,
          otp: this.otp
        }).then(res => {
          if (res.data.result.code === RESULT_CODE.SUCCESS) {
            this.$emit('input', true);
          } else {
            const message = res.data.result.message;
            if (message == '인증정보가 잘못되었습니다.') {
              this.notify('warning', '인증 시간이 만료되었습니다.');
              this.$emit('prev-step', {
                isOk: false,
                seqno: this.seqno,
                otp: this.otp
              });
              return;
            }
            this.notify('warning', message);
          }
        });
      },
      processModify() {
        this._validateBeforeSubmit(this.modifyPassword);
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      }
    }
  };
</script>
<style></style>
