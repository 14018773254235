<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-kakao py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header bg-transparent">
              <div class="row">
                <div class="col"><h3 class="h1 mb-0 text-center">OTP 인증</h3></div>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-5 position-relative">
              <form role="form" class="needs-validation" action="#" method="post" name="otpAuthForm" @submit.prevent="authenticate">
                <div class="row">
                  <div class="col-8">
                    <base-input placeholder="OTP 인증번호 입력"
                                type="text"
                                name="otp"
                                v-validate="'required'"
                                v-model="form.otp"
                                :maxlength="6">
                    </base-input>
                  </div>
                  <div class="col-4 text-right mt-2">
                    <span>{{ this.otpTime.convertTime }}</span>
                  </div>
                </div>
                <small class="text-danger " style="top:0.25rem;">{{errorMsg}}</small>

                <div class="text-center">
                  <base-button v-if="this.otpTime.otpProgressTime !== 0" type="primary" class="mt-4" wide native-type="submit" @click="confirmOtp">인증</base-button>
                  <base-button v-else type="primary" class="mt-4" wide native-type="button" @click="retryRequestOtp">재발송</base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import VueCookies from 'vue-cookies';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { USER_API_PREFIX, RESULT_CODE } from '@/shared/util/const';

  Vue.use(VueCookies);

  export default {
    name: 'OtpAuth',
    inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
    mixins: [ commValidatorMixin ],
    data() {
      return {
        form: {
          otp: '',
          userId : ''
        },
        errorMsg: undefined,
        otpAuthYn: 'N',
        otpTimer : null,
        otpTime : {
          defaultTime: 180,
          otpProgressTime: 0
        }
      };
    },
    created() {
      // 사용자아이디 설정
      this.form.userId = this.$route.query.userId
    },
    mounted() {
      // OTP 인증요청
      this.requestOtp();
    },
    methods: {
      authenticate() {
        return
      },
      /**
       * OTP 타이머 시작
       */
      startOtpTimer() {
        this.otpTime.otpProgressTime = this.otpTime.defaultTime
        this.convertSecondsToTimeFormat(this.otpTime.otpProgressTime)
        this.otpTimer = setInterval(this.convertProgressTime, 1000)
      },
      /**
       * OTP 인증 남은시간 처리
       */
      convertProgressTime() {
        let timer = this.otpTime.otpProgressTime
        this.otpTime.otpProgressTime = timer - 1

        // 타이머 초를 분 단위로 변환
        this.convertSecondsToTimeFormat(this.otpTime.otpProgressTime)

        // 타이머 종료시 처리
        if(this.otpTime.otpProgressTime === 0) {
          clearInterval(this.otpTimer)
        }
      },
      /**
       * 초를 분 단위로 변환
       * @param convertSeconds
       */
      convertSecondsToTimeFormat(convertSeconds) {
        let minutes = Math.floor(convertSeconds / 60)
        let seconds = (convertSeconds % 60)
        this.otpTime.convertTime = minutes + ':' + this.zeroLeftleftPadding(seconds)
      },
      /**
       * 숫자가 1자리수일 때 2자리수로 변환
       * @param target
       * @returns {*}
       */
      zeroLeftleftPadding(target) {
        return target.toString().length === 1 ? ('0' + target) : target.toString()
      },
      /**
       * OTP 인증 요청
       */
      requestOtp() {
        this.$axios.post(`${USER_API_PREFIX}/otp/request-otp`, {
          userId: this.form.userId
        }).then(res => {
          if(res.data.result.code === RESULT_CODE.FAIL) {
            this.errorMsg = res.data.result.message
          } else {
            // OTP 타이머 시작
            this.startOtpTimer();
          }
        }).catch(error => {
          console.log(error.response)
        })

        return false
      },
      /**
       * OTP 재전송 요청
       */
      retryRequestOtp() {
        this.form.otp = ''

        // OTP 인증요청
        this.requestOtp();
      },
      /**
       * OTP 인증 처리
       */
      confirmOtp() {
        this.$axios.post(`${USER_API_PREFIX}/otp/confirm-otp`, {
          userId: this.form.userId,
          otp: this.form.otp
        }).then(res => {
          if(res.data.result.code === RESULT_CODE.FAIL) {
            this.errorMsg = res.data.result.message
            this.form.otp = ''
            return
          }

          // 세션처리 후 이동
          sessionStorage.setItem('confirmOtpYn', 'Y');
          this.$store.dispatch('SessionStore/callSession', this.$route)
            .then(() => {
              // do nothing
            }).catch(() => {
            // do nothing
          });
        }).catch(error => {
          console.log(error.response)
        })
      }
    }
  };
</script>
<style>
  .bg-gradient-kakao{
    background : linear-gradient(87deg, #FFCD00 0, #F7F008 100%) !important;
  }
  .bg-kakao{
    background : #FFCD00 !important;
  }
  .bg-kakao-dark{
    background : #433530 !important;
  }
  .fill-kakao{
    background : #433530 !important;
  }
  .btn-kakao{
    background : #FFCD00 !important;
    border-color : #FFCD00 !important;
  }
</style>
