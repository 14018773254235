<template>
  <div>
    <h3 class="heading-title ">비밀번호 변경 결과</h3>
    <p>비밀번호가 변경되었습니다.</p>
  </div>
</template>

<script>
  export default {
    name: 'FindPasswordResult'
  };
</script>
<style></style>
