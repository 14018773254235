<template>
  <div>
    <form role="form" class="needs-validation" @submit.prevent="processGet">
      <base-input placeholder="이름"
                  name="name"
                  :maxlength="20"
                  :error="_getError('name')"
                  v-validate="'required'"
                  v-model="model.name">
      </base-input>

      <base-input placeholder="휴대폰번호"
                  name="mobileNo"
                  :error="_getError('mobileNo')"
                  v-validate="'required'"
                  v-model="model.mobileNo"
                  :maxlength="13"
                  @keyup="phoneMask">
      </base-input>
      <div class="text-center">
        <base-button type="kakao" wide native-type="submit">아이디 찾기</base-button>
      </div>
    </form>
  </div>
</template>

<script>
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { USER_API_PREFIX, RESULT_CODE, SITE_CODE } from '@/shared/util/const';

  export default {
    inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
    name: 'fineIdForm',
    mixins: [ commJsUtilMixin, commValidatorMixin ],
    data() {
      return {
        model: {
          name: '',
          mobileNo: ''
        }
      }
    },
    methods: {
      get() {
        this.$axios.get(`${USER_API_PREFIX}/find-id`, {
          params: {
            name: this.model.name,
            mobileNo: this.model.mobileNo,
            domain: SITE_CODE.USER
          }
        })
        .then(res => {
          if (res.data.result.code === RESULT_CODE.SUCCESS) {
            let userId = res.data.result.userId;
            if(userId) {
              this.$emit('input', {
                isOk: true,
                userId: userId
              });
            } else {
              this.notify('warning');
            }
          } else {
            this.notify('warning', res.data.result.message);
          }
        });
      },
      processGet() {
        this._validateBeforeSubmit(this.get);
      },
      /**
       * 키 이벤트로 핸드폰 번호 형식으로 문자열 변경
       */
      phoneMask() {
        this.model.mobileNo = this._autoHyphenPhoneNumber(this.model.mobileNo);
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      }
    }
  };
</script>
<style></style>
