<template>
  <div>
    <h3 class="heading-title ">아이디 찾기 결과</h3>
    <p>검색된 아이디는 <span class="text-danger font-weight-bolder">{{ userId }}</span> 입니다.</p>
  </div>
</template>

<script>
  export default {
    name: 'FindIdResult',
    props: {
      userId: {
        type: String,
        description: '아이디'
      }
    }
  };
</script>
<style></style>
